/**
 * Small note for form field
 */

@use 'sass:map';

.form-note {
	display: block;
	margin-top: rem-calc(3);
	margin-bottom: rem-calc(3);

	font-size: rem-calc(14);
	line-height: 1.3;

	color: map.get($layout, color-secondary);
}
