/**
 * Typography definition for large content
 */

.typo {
	& > * {
		&:first-child {
			margin-top: 0;
		}



		&:last-child {
			margin-bottom: 0;
		}
	}



	/**
	 * Typography resets
	 */

	dl,
	dt,
	dd,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	pre,
	form,
	p,
	blockquote,
	th,
	td,
	address,
	table {
		margin: 0;
		padding: 0;
	}

	dd,
	ul,
	ol,
	pre,
	form,
	p,
	table,
	blockquote,
	address,
	table {
		margin-bottom: set-style($typo, paragraph, margin-bottom);
	}



	/**
	 * Default paragraph styles
	 */

	p {
		font-family: inherit;
		font-size: inherit;
		font-weight: set-font-weight(primary, normal);
		line-height: set-style($typo, paragraph, line-height);
	}



	/**
	 * Definitions lists
	 */

	dl,
	dd,
	dt {
		line-height: set-style($typo, paragraph, line-height);
	}

	dl {
		font-family: inherit;
		font-size: inherit;
		font-weight: set-font-weight(primary, normal);
	}

	dt {
		margin-bottom: rem-calc(2);

		color: #b3b3b3;
	}



	/**
	 * Headers
	 */

	h1,
	h2,
	h3,
	h4,
	h5 {
		line-height: set-style($typo, header, line-height);
		font-family: set-font(secondary);

		color: set-style($typo, header, color);
	}

	h1 {
		margin-top: 0.8333em;
		margin-bottom: 0.7em;

		font-size: 2.5em;
		font-weight: set-font-weight(secondary, bold);
	}

	h2 {
		margin-top: 1.4285em;
		margin-bottom: 0.67em;

		font-size: 1.8em;
		font-weight: set-font-weight(secondary, normal);
	}

	h3 {
		margin-top: 1.35em;
		margin-bottom: 0.6em;

		font-size: 1.5em;
		font-weight: set-font-weight(secondary, normal);
	}

	h4 {
		margin-top: 1.35em;
		margin-bottom: 0.6em;

		font-size: 1.35em;
		font-weight: set-font-weight(secondary, normal);
	}

	h5 {
		margin-top: 1.35em;
		margin-bottom: 0.6em;

		font-size: 1.25em;
		font-weight: set-font-weight(secondary, normal);
	}

	h6 {
		margin-top: 1.35em;
		margin-bottom: 0.6em;
		font-size: 1.125em;
	}



	/**
	 * Lists
	 */

	ol,
	ul {
		li {
			margin-bottom: rem-calc(10);

			line-height: set-style($typo, paragraph, line-height);
		}
	}



	/**
	 * Tables
	 */

	table {
		width: 100%;

		font-size: inherit;

		border-collapse: collapse;
		border: 1px solid set-style($typo, table, border-color);
		color: set-style($typo, table, color);



		caption {
			margin-bottom: 10px;

			color: #b3b3b3;
		}



		th {
			font-weight: set-font-weight(primary, bold);
		}



		td,
		th {
			padding: 11px 7px;

			text-align: left;

			border: 0;



			&:first-child {
				padding-left: 11px;
			}



			&:last-child {
				padding-right: 11px;
			}
		}



		thead {
			font-family: set-font(secondary);
			line-height: set-style($typo, header, line-height);

			background-color: set-style($typo, table, bg-header);
		}



		tr {
			&:not(:last-child) {
				td,
				th {
					border-bottom: 1px solid set-style($typo, table, border-color);
				}
			}
		}



		img {
			box-shadow: none;
			border-radius: 0;
		}
	}
}
