/**
 * Placeholder for form fields
 */

@use 'sass:map';
@use 'sass:math';

%form-field {
	box-sizing: border-box;
	display: block;
	margin: 0;
	width: 100%;
	overflow: hidden;
	padding: math.div(map.get($form, form-spacing), 2);
	height: map.get($form, input-font-size) + (map.get($form, form-spacing) * 1.5);

	font-family: inherit;
	font-weight: set-font-weight(primary, normal);
	font-size: map.get($form, input-font-size);
	text-transform: none;

	background-color: map.get($form, field-bg);
	color: map.get($form, field-color);
	border: 1px solid map.get($form, field-border-color);
	border-radius: $global-radius;
	box-shadow: none;
	appearance: none;
	transition-property: border-color, background-color, box-shadow;
	transition-duration: $default-transition-duration;
	transition-timing-function: $default-transition-function;
	cursor: pointer;



	&:hover {
		border-color: map.get($form, field-border-color-hover);
		box-shadow: inset 0 1px 5px 0 rgba(#000, 0.1);
	}



	&:focus {
		background-color: map.get($form, field-bg-focus);
		border-color: map.get($form, field-border-color-focus);
		box-shadow: inset 0 1px 5px 0 rgba(#000, 0.1);
		outline: none;
	}



	&:disabled,
	&[disabled] {
		background-color: map.get($form, field-bg-disabled);
		cursor: default;
	}



	&:invalid {
		.validated & {
			border-color: map.get($form, field-border-color-alert);
		}
	}



	/* stylelint-disable */
	&::-webkit-input-placeholder {
		font-style: normal;

		color: map.get($form, field-color-placeholder);
	}

	// Firefox 18-
	&:-moz-placeholder {
		font-style: normal;

		color: map.get($form, field-color-placeholder);
	}

	// Firefox 19+
	&::-moz-placeholder {
		font-style: normal;

		color: map.get($form, field-color-placeholder);
	}

	&:-ms-input-placeholder {
		font-style: normal;

		color: map.get($form, field-color-placeholder);
	}

	&::-ms-clear {
		display: none;
	}
	/* stylelint-enable */
}
