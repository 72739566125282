/**
 * Page footer
 */

@use 'sass:map';
@use 'sass:math';

.footer {
	display: block;
	padding: 25px math.div($column-gutter, 2);

	text-align: center;
	font-size: rem-calc(14);

	background-color: map.get($layout, bg-secondary);
	color: #ededed;
}
