// Make sure the charset is set appropriately
@charset 'UTF-8';



/**
 * Utilities
 */

@import 'utilities/fonts';
@import 'utilities/functions';
@import 'utilities/keyframes';



/**
 * Settings
 */

@import 'settings';



/**
 * Utilities
 */

@import 'utilities/placeholders';



/**
 * Third-party libraries
 */

@import '_normalize.css';



/**
 * Base
 */

@import 'basic';



/**
 * Layout
 */

@import 'layout/body';
@import 'layout/footer';
@import 'layout/header';



/**
 * Box
 */

@import 'atoms/boxes/box';



/**
 * Buttons
 */

@import 'atoms/buttons/button';



/**
 * Form label
 */

@import 'atoms/forms/form-label';
@import 'atoms/forms/form-note';
@import 'atoms/forms/overlay-response';
@import 'atoms/forms/text-area';
@import 'atoms/forms/text-field';



/**
 * Images
 */

@import 'atoms/images/site-logo';



/**
 * Structures
 */

@import 'atoms/structures/attached-note';



/**
 * Typos
 */

@import 'atoms/typos/typo';
