/**
 * Overlay layer with response messages
 */

@use 'sass:map';

.overlay-response {
	position: relative;



	&__response {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: none;
		align-items: center;
		justify-content: center;

		opacity: 0;
		outline: none;
	}



	&__message {
		font-size: rem-calc(18);
		font-weight: set-font-weight(primary, bold);
		text-align: center;



		&--success {
			color: map.get($ui, success);
		}



		&--error {
			color: map.get($ui, alert);
		}
	}



	&--show-overlay {
		.overlay-response {
			&__source {
				animation: fade-out 0.3s ease-in;
				animation-fill-mode: forwards;
			}



			&__response {
				display: flex;

				animation: fade-in 0.3s ease-in;
				animation-delay: 0.3s;
				animation-fill-mode: forwards;
			}
		}
	}
}
