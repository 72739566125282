/**
 * Definitions for basic HTML elements
 *
 * 1. Prevent fonts from getting bigger when rotating to landscape
 */

@use 'sass:map';

*,
*::before,
*::after {
	box-sizing: border-box;
}



/* [1] */
html {
	text-size-adjust: 100%;
}



/**
 * Default font definition
 */

body {
	font-family: set-font(primary);
	font-size: rem-calc(16);
	font-weight: set-font-weight(primary, normal);

	color: map.get($layout, color-primary);
	background-color: map.get($layout, bg-primary);

	-webkit-tap-highlight-color: transparent;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}



/**
 * Responsive images
 */

img {
	max-width: 100%;
	height: auto;

	interpolation-mode: bicubic; /* stylelint-disable-line property-no-unknown */
}



/**
 * Basic anchors
 */

a {
	text-decoration: none;

	color: map.get($anchor, color);
	border-bottom: 1px solid rgba(map.get($anchor, color), 0.3);

	transition: color, border-color;
	transition-duration: $default-transition-duration;
	transition-timing-function: $default-transition-function;
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;



	&:hover,
	&:focus {
		color: map.get($anchor, hover-color);
		border-color: rgba(map.get($anchor, color), 0.7);
	}
}



/**
 * Bold texts
 */

strong,
b {
	font-weight: set-font-weight(primary, bold);
}
