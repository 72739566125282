/**
 * Basic box
 */

@use 'sass:map';

.box {
	display: block;
	padding: rem-calc(20);

	border-radius: $global-radius;
	background: map.get($layout, bg-ternary);
	border: 1px solid map.get($layout, border-color);



	&__title {
		display: block;
		margin: rem-calc(0 0 30);

		text-align: center;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-size: rem-calc(18);
		line-height: set-style($typo, header, line-height);
		font-family: set-font(secondary);
		font-weight: set-font-weight(secondary, bold);

		color: map.get($layout, color-secondary);
	}
}



@media #{$medium-up} {
	.box {
		padding: rem-calc(30);
	}
}
