/**
 * Text area
 */

.text-area {
	@extend %form-field;


	resize: vertical;
	height: 130px;
	overflow: visible;



	// Respect enforced amount of rows for textarea
	&[rows] {
		height: auto;
	}
}
