/**
 * Page header
 */

@use 'sass:math';

.header {
	display: block;
	padding: 30px math.div($column-gutter, 2);

	text-align: center;
}



@media #{$medium-up} {
	.header {
		padding-top: 40px;
		padding-bottom: 40px;
	}
}



@media #{$large-up} {
	.header {
		padding-top: 50px;
		padding-bottom: 50px;
	}
}
