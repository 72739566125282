/**
 * Fade-in animation
 */

@keyframes fade-in {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}



/**
 * Fade-out animation
 */

@keyframes fade-out {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}
