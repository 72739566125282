@use 'sass:color';
@use 'sass:map';

/**
 * Sizes
 */

$column-gutter: 20px;
$rem-base: 16px;
$global-radius: 4px;
$max-content-width: 1020px;



/**
 * Basic config
 */

$ui: (
	primary: #007ef3,
	secondary: #fe8719,
	success: #11a864,
	warning: #f4a21e,
	alert: #dd3f4f,
);
$layout: (
	bg-primary: #fff,
	bg-secondary: rgba(#12181f, 0.8),
	bg-ternary: #ecf0f3,
	color-primary: #12181f,
	color-secondary: #5c6773,
	border-color: #e8e8e8,
);



/**
 * Default transitions variables
 */

$default-transition-duration: 250ms;
$default-transition-function: ease-out;



/**
 * Anchors
 */

$anchor: (
	color: map.get($ui, primary),
	hover-color: color.scale(map.get($ui, primary), $lightness: -14%),
);



/**
 * Fonts
 */

$font-family: (
	primary: ('proxima-nova', sans-serif),
	secondary: ('proxima-nova', sans-serif),
);
$font-weight: (
	primary: (
		normal: 300,
		bold: 400,
	),
	secondary: (
		normal: 400,
		bold: 700,
	),
);



/**
 * Typography
 */

$typo: (
	header: (
		line-height: 1.18,
		color: #000,
	),
	paragraph: (
		margin-bottom: 1.25em,
		line-height: 1.4,
	),
	table: (
		bg-header: #efefef,
		border-color: map.get($layout, border-color),
		color: #000,
	),
);



/**
 * Forms
 */

$form: (
	form-spacing: rem-calc(20),
	input-font-size: rem-calc(18),
	field-bg: #fff,
	field-bg-alert: #fffafa,
	field-bg-disabled: #ddd,
	field-bg-focus: #fff,
	field-border-color: rgba(#000, 0.12),
	field-border-color-alert: rgba(map.get($ui, alert), 0.5),
	field-border-color-focus: rgba(map.get($ui, secondary), 0.5),
	field-border-color-hover: rgba(map.get($ui, secondary), 0.5),
	field-color: map.get($layout, color-primary),
	field-color-placeholder: #98a5b3,
);



/**
 * Buttons
 */

$button: (
	bg: #42cc67,
	bg-disabled: #98a5b3,
	color: #fff,
);



/**
 * Responsive breakpoints
 */

$small-range: (0, 40em);
$smartphones-range: (23.4em, 40em);
$medium-range: (40.063em, 73em);
$large-range: (73.063em, 90em);
$xlarge-range: (90.063em, 120em);
$xxlarge-range: (120.063em, 99999999em);
$screen: 'only screen';
$landscape: '#{$screen} and (orientation: landscape)';
$portrait: '#{$screen} and (orientation: portrait)';
$iphone: '#{$screen} and (max-width: 352px)';
$small-up: $screen;
$small-only: '#{$screen} and (max-width: #{upper-bound($small-range)})';
$landscape-small-only: '#{$landscape} and (max-width: #{upper-bound($small-range)})';
$smartphones-from: lower-bound($smartphones-range);
$smartphones-to: upper-bound($smartphones-range);
$smartphones-up: '#{$screen} and (min-width:#{$smartphones-from})';
$smartphones-only: '#{$screen} and (min-width:#{$smartphones-from}) and (max-width: #{$smartphones-to})';
$medium-from: lower-bound($medium-range);
$medium-to: upper-bound($medium-range);
$medium-up: '#{$screen} and (min-width:#{$medium-from})';
$medium-only: '#{$screen} and (min-width:#{$medium-from}) and (max-width:#{$medium-to})';
$landscape-medium-up: '#{$landscape} and (min-width:#{$medium-from})';
$portrait-medium-up: '#{$portrait} and (min-width:#{$medium-from})';
$landscape-medium-only: '#{$landscape} and (min-width:#{$medium-from}) and (max-width:#{$medium-to})';
$portrait-medium-only: '#{$portrait} and (min-width:#{$medium-from}) and (max-width:#{$medium-to})';
$large-from: lower-bound($large-range);
$large-to: upper-bound($large-range);
$large-up: '#{$screen} and (min-width:#{$large-from})';
$large-only: '#{$screen} and (min-width:#{$large-from}) and (max-width:#{$large-to})';
$xlarge-from: lower-bound($xlarge-range);
$xlarge-to: upper-bound($xlarge-range);
$xlarge-up: '#{$screen} and (min-width:#{$xlarge-from})';
$xlarge-only: '#{$screen} and (min-width:#{$xlarge-from}) and (max-width:#{$xlarge-to})';
$xxlarge-from: lower-bound($xxlarge-range);
$xxlarge-to: upper-bound($xxlarge-range);
$xxlarge-up: '#{$screen} and (min-width:#{$xxlarge-from})';
$xxlarge-only: '#{$screen} and (min-width:#{$xxlarge-from}) and (max-width:#{$xxlarge-to})';
$small: $medium-up;
$medium: $medium-up;
$large: $large-up;
