/**
 * Site logo
 */

.site-logo {
	display: inline-block;
	vertical-align: middle;
	width: 260px;
	height: 68px;

	text-align: left;
	text-indent: -9999px;

	background: url(../images/logo.svg) no-repeat 50% 50%;
	background-size: contain;
}
