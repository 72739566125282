/**
 * Button
 */

@use 'sass:color';
@use 'sass:map';

.button {
	display: inline-flex;
	align-items: stretch;
	vertical-align: middle;
	padding: 0 0 2px;
	margin: 0;
	height: rem-calc(56);

	text-align: center;
	line-height: 1;
	font-size: rem-calc(22);
	font-family: set-font(secondary);
	font-weight: set-font-weight(secondary, normal);
	text-decoration: none;

	box-shadow: inset 0 -3px 0 0 color.adjust(map.get($button, bg), $lightness: -12%);
	background: transparent;
	color: map.get($button, color);
	transition-property: box-shadow, opacity;
	transition-duration: 0.2s;
	transition-timing-function: ease-in-out;
	border: 0;
	border-radius: $global-radius $global-radius ($global-radius + 1) ($global-radius + 1);
	cursor: pointer;
	appearance: none;
	outline: none;



	&:hover,
	&:focus {
		background-color: transparent;
		color: map.get($button, color);
	}



	& > * {
		position: relative;
		display: flex;
		flex: 1 1 auto;
		justify-content: center;
		flex-direction: column;
		width: 100%;
		height: rem-calc(54);
		padding: rem-calc(10);

		line-height: 1;

		background-color: map.get($button, bg);
		border-radius: $global-radius;
		border-left: 1px solid map.get($button, bg);
		border-right: 1px solid map.get($button, bg);
		border-top: 1px solid map.get($button, bg);

		backface-visibility: hidden;
		transform: translateZ(0);
		transition-property: transform, background, color, border-color, box-shadow;
		transition-duration: 0.2s;
		transition-timing-function: ease-in-out;
	}



	&:disabled,
	&[disabled] {
		box-shadow: inset 0 -3px 0 0 color.adjust(map.get($button, bg-disabled), $lightness: -12%);

		& > * {
			background: map.get($button, bg-disabled);
			border-color: map.get($button, bg-disabled);
			color: map.get($button, color);
			cursor: not-allowed;
		}
	}



	&:hover,
	&:focus,
	&:active {
		&:not(:disabled, [disabled]) {
			& > * {
				background-color: color.mix(#000, map.get($button, bg), 7%);
				border-left: 1px solid color.mix(#000, map.get($button, bg), 7%);
				border-right: 1px solid color.mix(#000, map.get($button, bg), 7%);
				border-top: 1px solid color.mix(#000, map.get($button, bg), 7%);
			}
		}
	}



	&:active:not(:disabled, [disabled]) {
		& > * {
			transform: translate(0, 2px);
		}
	}



	/**
	 * Styles
	 */

	&--fullwidth {
		width: 100%;
	}
}
