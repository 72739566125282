/**
 * Webfont definitions
 *
 * Font has been downloaded from Typekit account.
 */

/* stylelint-disable */
@font-face {
	font-family: 'proxima-nova';
	src: url('../fonts/proxima-nova-normal-300.woff2') format('woff2'), url('../fonts/proxima-nova-normal-300.woff') format('woff');
	font-style: normal;
	font-weight: 300;
	font-display: swap;
}

@font-face {
	font-family: 'proxima-nova';
	src: url('../fonts/proxima-nova-normal-400.woff2') format('woff2'), url('../fonts/proxima-nova-normal-400.woff') format('woff');
	font-style: normal;
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: 'proxima-nova';
	src: url('../fonts/proxima-nova-normal-700.woff2') format('woff2'), url('../fonts/proxima-nova-normal-700.woff') format('woff');
	font-style: normal;
	font-weight: 700;
	font-display: swap;
}
/* stylelint-enable */
