/**
 * Form label
 */

.form-label {
	display: block;
	margin: 0;

	font-size: rem-calc(18);
	font-weight: set-font-weight(primary, normal);
	line-height: 1.4;



	&:not(:last-child) {
		margin-bottom: rem-calc(20);
	}
}
