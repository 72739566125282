/**
 * Page body
 */

@use 'sass:math';

.body {
	max-width: $max-content-width;
	margin-left: auto;
	margin-right: auto;
	padding: 0 math.div($column-gutter, 2);



	&__title {
		margin-bottom: rem-calc(35);
	}



	&__title,
	&__content {
		font-size: rem-calc(18);
	}



	&__content,
	&__sidebar {
		margin-bottom: rem-calc(40);
	}
}



@media #{$screen} and (min-width: 48em) {
	.body {
		&__container {
			display: flex;
		}



		&__content {
			flex: 1 1 auto;
		}



		&__sidebar {
			flex: 0 0 320px;
			margin-left: 40px;
		}
	}
}



@media #{$large-up} {
	.body {
		&__title,
		&__content {
			font-size: rem-calc(20);
		}



		&__content,
		&__sidebar {
			margin-bottom: rem-calc(60);
		}



		&__sidebar {
			margin-left: 80px;
		}
	}
}
