/**
 * Note attached to source element
 */

.attached-note {
	&__note {
		margin-top: rem-calc(13);

		font-size: rem-calc(14);
		line-height: 1.4;
		text-align: center;
		opacity: 0.8;
	}
}
